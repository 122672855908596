<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-09-26 17:01:25
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-05 12:22:00
 * @FilePath: /mediatom-web/src/viewsForManage/Finance/CapitalManager/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="capital-manager-container">
    <div class="sum-data-box">
      <div class="sum-data">
        <span>账号总金额：{{ moneyType }}{{ numFormat(aggregateAmount, 3, '') }}</span>
        <span>账号已消耗金额：{{ moneyType }}{{ numFormat(consumeAmount, 3, '') }}</span>
        <span>账号可用金额：{{ moneyType }}{{ numFormat(residueAmount, 3, '') }}</span>
      </div>
      <a-button type="primary" disabled>充值</a-button>
    </div>
    <div class="search-box">
      <a-range-picker
        class="search-item"
        format="YYYY-MM-DD"
        style="width: 240px"
        :placeholder="['Start', 'End']"
        @change="ChangeDate"
        :value="rangePickerDate"
        :allowClear="false"
        :ranges="{
          今日: [initDate(0, 0).start, initDate(0, 0).end],
          昨日: [initDate(1, 1).start, initDate(1, 1).end],
          最近一周: [initDate(6, 0).start, initDate(6, 0).end],
          最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
        }"
        :disabledDate="disabledDate"
      >
      </a-range-picker>
      <m-select
        v-model="searchquery.type"
        :showSearch="false"
        :dataList="typelist"
        label="业务类型"
        class="search-item"
        @change="handleSearch"
      />
    </div>
    <div class="table-box">
      <a-spin :spinning="isLoading">
        <el-table ref="table" :data="tableData" style="width: 100%" @sort-change="handleSortChange">
          <!-- 入账时间 -->
          <el-table-column prop="createTime" label="入账时间" min-width="140">
          </el-table-column>
          <!-- 业务类型 -->
          <el-table-column prop="type" label="业务类型" min-width="140">
            <template slot-scope="{row}">
              {{ getTypeLabel(row) }}
            </template>
          </el-table-column>
          <!-- 收支金额 -->
          <el-table-column prop="type" label="收支金额" min-width="140">
            <template slot-scope="{row}">
              {{ moneyType }}{{ +row.type === 1 ? '+' : '-' }}{{ numFormat(row.amount, 3, '') }}
            </template>
          </el-table-column>
          <!-- 可用余额 -->
          <el-table-column prop="type" label="可用余额" min-width="140">
            <template slot-scope="{row}">
              {{ moneyType }}{{ numFormat(row.residueAmount, 3, '') }}
            </template>
          </el-table-column>
        </el-table>
        <m-empty v-if="tableData.length === 0" style="height: 341px" imgHeight="176px">
          <template #description> 暂时没有任何数据 </template>
        </m-empty>
        <a-pagination
          class="pagination"
          v-if="tableData.length > 0"
          show-size-changer
          :current="searchquery.page"
          :total="total"
          :show-total="(total) => `共 ${total} 条`"
          @change="handleChangePage"
          @showSizeChange="onShowSizeChange"
          :pageSize="searchquery.limit"
        />
      </a-spin>
    </div>
  </div>
</template>

<script>
import { getCapitalSumData, getCapitalData } from '@/apiForManage/finance/capital'
import { mapState } from 'vuex'
import { numFormat } from '@/utils/dealNumber'
import mixDate from '@/mixins/initDate'
import { getDuration } from '@/utils/duration'
export default {
  name: 'CapitalManager',
  mixins: [mixDate],
  data () {
    return {
      typelist: [
        {
          name: '充值',
          id: 1
        },
        {
          name: '退款',
          id: 2
        },
        {
          name: '业务消耗',
          id: 3
        }
      ],
      aggregateAmount: 0,
      consumeAmount: 0,
      residueAmount: 0,
      searchquery: {
        dateStart: '',
        dateEnd: '',
        page: 1,
        limit: 10,
        sortBy: undefined,
        sortOrder: undefined,
        type: undefined
      },
      tableData: [],
      isLoading: false,
      total: 0
    }
  },
  computed: {
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      roleId: (state) => state.user.roleId,
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      moneyType: (state) => (+state.user.cur === 1 ? '$' : '¥'),
      currentLoginId: (state) => state.user.id
    })
  },
  created () {
    this.initPageDate()
    this.handleSearch()
  },
  methods: {
    numFormat,
    getTypeLabel (row) {
      const { type } = row
      const { name } = this.typelist.find((item) => item.id === type)
      return name
    },
    initPageDate () {
      // 初始化时间
      const { endDate, startDate, end, start } = this.initDate(6, 0)
      this.rangePickerDate = [start, end]
      this.searchquery.dateEnd = endDate
      this.searchquery.dateStart = startDate
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    ChangeDate (date, dateString) {
      this.searchquery = {
        ...this.searchquery,
        dateEnd: dateString[1],
        dateStart: dateString[0]
      }
      this.rangePickerDate = date
      this.handleSearch()
    },
    handleSearch () {
      this.searchquery.page = 1
      this.searchquery.sortBy = undefined
      this.searchquery.sortOrder = undefined
      this.$nextTick(() => {
        this.$refs.table.clearSort()
      })
      this.getCapitalSumData()
      this.getDataList()
    },
    async getCapitalSumData () {
     const { data = {} } = await getCapitalSumData()
     const { aggregateAmount = 0, consumeAmount = 0, residueAmount = 0 } = data
     this.aggregateAmount = aggregateAmount
     this.consumeAmount = consumeAmount
     this.residueAmount = residueAmount
    },
    async getDataList () {
      this.isLoading = true
      const { data = {} } = await getCapitalData(this.searchquery)
      const { items = [], total = 0 } = data
      this.tableData = items
      this.total = total
      this.isLoading = false
    },
    handleSortChange ({ column, prop, order }) {
      if (order === 'ascending') {
        this.searchquery.sortOrder = 'asc'
      } else if (order === 'descending') {
        this.searchquery.sortOrder = 'desc'
      } else {
        this.searchquery.sortOrder = undefined
      }
      this.searchquery.sortBy = this.searchquery.sortOrder ? prop : undefined
      this.searchquery.page = 1
      this.getDataList()
    },
    handleChangePage (page) {
      this.searchquery.page = page
      this.getDataList()
    },
    onShowSizeChange (current, size) {
      this.searchquery.page = 1
      this.searchquery.limit = size
      this.getDataList()
    }
  }
}
</script>

<style lang="less" scoped>
.capital-manager-container{
  margin: @mediumMargin;
}
.sum-data-box, .table-box{
  border-radius: @mediumRadius;
  box-shadow: @modelShadow;
  border: 1px solid @modelBorderColor;
  background-color: #fff;
  padding: @smallMargin;
  margin-bottom: @mediumMargin;
}
.search-box{
  margin-bottom: @mediumMargin;
  .search-item{
    width: 220px;
    margin-right: @compMargin;
  }
}
.sum-data-box{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .sum-data{
    display: flex;
    align-items: center;
    span{
      &:not(:last-child)::after{
        content: '';
        display: inline-block;
        width: 1px;
        height: 12px;
        background-color: @modelBorderColor;
        margin: 0 @smallMargin;
      }
    }
  }
}
.pagination {
  padding: @smallMargin 0;
}
</style>
